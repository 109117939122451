import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const TermsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("footer.link-terms")} />
      <article className="article legal">
        <h1 className="heading heading--md">
          Allgemeine Geschäftsbedingungen für den Service "Collect &
          Deliver-App"
        </h1>

        <p>Lizenz- und Nutzungsbedingungen für die „Collect & Deliver-App“</p>

        <p>
          der tiramizoo GmbH, vertreten durch den Geschäftsführer Martin Sträb,
          Wilhelm-Hale-Str. 50, 80639 München (nachfolgend „Unternehmen“)
        </p>

        <p>1. Allgemeines; Vertragsgegenstand</p>

        <p>
          1.1. Diese Lizenz- und Nutzungsbedingungen (nachfolgend
          „Nutzungsbedingun-gen“) gelten für die Nutzung der von dem Unternehmen
          angebotenen Collect & Deliver- App (nachfolgend „C&D-App“) durch
          Nutzer der C&D-App (nachfol-gend „Nutzer“).
        </p>

        <p>
          1.2. Vertragsgegenstand ist die kostenfreie und kostenpflichtige
          Nutzung der C&D-App durch Nutzer.
        </p>

        <p>
          1.3. In der C&D-App können die Nutzer die schnellste städtische
          Lieferroute auf ih-rem digitalen mobilen Endgerät (z.B. Smartphone,
          Tablet) ermitteln. Die C&D-App ermöglicht es dem Nutzer vor allem,
          Lieferadressen manuell einzugeben und die schnellste Route zur
          Belieferung dieser Adressen berechnen zu las-sen, wobei diese Adressen
          („Stopps“) auf offenen Straßenkarten innerhalb der C&D-App
          visualisiert werden. Die Anzahl der Stopps je zu berechnender Route
          ist in jedem Fall auf 25 beschränkt.
        </p>

        <p>
          1.4. Die Nutzungsbedingungen gelten lediglich für die allgemeine
          Nutzung der C&D-App durch den Nutzer im Rahmen des in Ziffer 1
          beschriebenen Anwen-dungsbereichs. Das Vertragsverhältnis zwischen dem
          Nutzer und dem Unter-nehmen betreffend der Nutzung der C&D-App beginnt
          in dem Moment, in dem der Nutzer im Rahmen des Registrierungsprozesses
          diesen Nutzungsbedin-gungen zustimmt. Die Nutzungsbedingungen sind
          Bestandteil des Vertrags-verhältnisses.
        </p>

        <p>
          1.5. Die Bestimmungen dieser Nutzungsbedingungen gelten auch für dem
          Nutzer zukünftig zur Nutzung überlassene Versionen der C&D-App, soweit
          diese neu-en Versionen keine geänderten Bedingungen vorsehen.
        </p>

        <p>2. Nutzung der C&D-App</p>

        <p>
          2.1. Eine Nutzung der C&D-App ist nur möglich, wenn die
          Nutzungsbedingungen zuvor von dem Nutzer akzeptiert wurden.
        </p>

        <p>
          2.2. Die C&D-App ist für das Betriebssystem Android erhältlich und
          kann von dem Nutzer von der entsprechenden App-Plattform (Google Play
          Store) zu den dort geltenden Bedingungen kostenlos heruntergeladen und
          installiert werden. Gegebenenfalls fallen hierbei Kosten der
          Mobilfunkanbieter für die Datenüber-tragung auf das mobile Endgerät
          an.
        </p>

        <p>
          2.3. Der Nutzer ist verpflichtet, die C&D-App nur rechtmäßig nach
          Maßgabe dieser Nutzungsbedingungen und in Übereinstimmung mit dem
          anwendbaren Recht zu nutzen. Dafür steht der Nutzer ein.
        </p>

        <p>
          2.4. Der Nutzer haftet gegenüber dem Unternehmen für Schäden, die
          durch die schuldhafte Verletzung seiner Pflichten aus diesen
          Nutzungsbedingungen entstehen.
        </p>

        <p>3. Einräumung von Nutzungsrechten</p>

        <p>
          3.1. Das Unternehmen gewährt dem Nutzer ein nicht-exklusives, nicht
          übertragba-res und nicht unterlizenzierbares, unentgeltliches Recht
          zur Ausführung, An-zeige und Nutzung der C&D-App auf digitalen mobilen
          Endgeräten des Nut-zers. Die vertragsgemäße Nutzung der C&D-App
          umfasst die Installation sowie das Laden, Anzeigen und Ablaufenlassen
          der installierten C&D-App.
        </p>

        <p>
          3.2. Es ist dem Nutzer untersagt, die C&D-App zu unterlizenzieren, zu
          verleihen und/ oder in irgendeiner Form die C&D-App bzw. das Recht zur
          Nutzung der C&D-App zu verbreiten. Dem Nutzer ist es zudem untersagt,
          die C&D-App zu ändern, zu übersetzen, zurückzuentwickeln, zu
          dekompilieren oder abgeleitete Werke von der C&D-App zu erstellen.
        </p>

        <p>
          3.3. Sofern das Unternehmen während der Laufzeit neue Versionen,
          Updates, Up-grades oder andere Neulieferungen im Hinblick auf die
          C&D-App vornimmt, fin-den die Bestimmungen dieser Nutzungsbedingungen
          auch für diese uneinge-schränkte Anwendung.
        </p>

        <p>
          3.4. Rechte, die vorstehend nicht ausdrücklich dem Nutzer eingeräumt
          werden, stehen dem Nutzer im Zweifel nicht zu. Dieser ist insbesondere
          nicht berechtigt, die C&D-App über die vereinbarte Nutzung hinaus zu
          nutzen oder von Dritten nutzen zu lassen oder die C&D-App zugänglich
          zu machen. Insbesondere ist es nicht gestattet, die C&D-App zu
          vervielfältigen, zu veräußern oder zeitlich begrenzt zu überlassen,
          insbesondere nicht zu vermieten oder zu verleihen.
          Vervielfältigungsvorgänge technischer Art, welche für eine
          vertragsgemäße Nutzung erforderlich sind, bleiben außer Betracht.
        </p>

        <p>4. Geistiges Eigentum an der C&D-App</p>

        <p>
          4.1. Das Unternehmen ist alleiniger und ausschließlicher Inhaber der
          Rechte an der C&D-App. Der Nutzer erhält außer den ihm ausdrücklich in
          den Nutzungsbe-dingungen an der C&D-App eingeräumten Rechte keine
          weiteren Rechte.
        </p>

        <p>
          4.2. Sämtliche Elemente der C&D-App, d.h. Daten und Materialien
          einschließlich Bilder, Grafiken, Illustrationen, Designs, Symbole,
          Fotos, Texte und sonstige Abbildungen (nachfolgend „Inhalte“) stehen
          im Eigentum des Unternehmens oder sind diesem rechtmäßig zur Nutzung
          überlassen. Sie sind durch das Ur-heberrecht, das Markenrecht und/oder
          sonstige Rechte zum Schutz geistigen Eigentums geschützt.
        </p>

        <p>
          4.3. Jegliche Nutzung und/oder Vervielfältigung der Inhalte entgegen
          den Bestim-mungen dieser Nutzungsbedingungen oder ohne die
          ausdrückliche vorherige Zustimmung seitens des Unternehmens, verstößt
          gegen geltendes Recht und ist gemäß diesen Nutzungsbedingungen
          untersagt und berechtigt das Unter-nehmen, den Vertrag über die
          Nutzung der C&D-App mit dem Nutzer außeror-dentlich zu kündigen (gemäß
          Ziffer 7.1). Die Geltendmachung weitergehender Ansprüche, gerichtet
          zum Beispiel auf Unterlassung oder Schadensersatz, bleibt vorbehalten.
        </p>

        <p>5. Vergütung</p>

        <p>
          Eine Vergütung ist seitens des Nutzers nur dann geschuldet, soweit er
          bei Gebrauch der C&D-App auf zahlungspflichtige Dienste zugreift.
          Zahlungspflichtige Dienste können insbesondere darin bestehen, dem
          Nutzer die Möglichkeit einzuräumen, mit-tels der C&D-App Leistungen
          gemäß Ziffer 1.3 in einem bestimmten Umfang, der über denjenigen einer
          kostenfreien Nutzung hinausgeht, in Anspruch zu nehmen. Der Erwerb des
          Zugangs zu den zahlungspflichtigen Diensten sowie die
          Zahlungs-abwicklung erfolgt über die jeweilige App-Plattform (zum
          Beispiel Apple App Store, Google Play Store), über welche der Nutzer
          die C&D-App bezogen hat. Dabei wird der zahlungspflichtige Dienst in
          der Regel für einen bestimmten wiederkehrenden Zeitabschnitt (z.B.
          kalendermonatlich) gebucht („Abonnement“). In jedem Fall ist die
          Vergütung im Falle eines Abonnements stets im Voraus für den jeweils
          vereinbarten wiederkehrenden Zeitabschnitt zu entrichten.
        </p>

        <p>6. Beendigung des Vertrages/ Sperrung der C&D-App</p>

        <p>
          6.1. Das Unternehmen und der Nutzer können den Vertrag über die
          Nutzung der C&D-App aus wichtigem Grund außerordentlich kündigen. Ein
          wichtiger Grund liegt für das Unternehmen z.B. vor, wenn (i) der
          Nutzer nachhaltig und schwer-wiegend gegen seine vertraglichen
          Pflichten, insbesondere gemäß dieser Nut-zungsbedingungen verstößt,
          oder (ii) wenn das Unternehmen beschließt, die C&D-App nicht mehr
          anzubieten bzw. wenn das Unternehmen den Betrieb der C&D-App
          einstellt. Die außerordentliche Kündigung wie auch eine Kündigung
          gemäß der nachfolgenden Ziffern 7.2 und 7.3 bedarf mindestens der
          Textform (§ 126b BGB).
        </p>

        <p>
          6.2. Der Nutzer ist berechtigt, den Vertrag über die Nutzung der
          C&D-App jederzeit mit sofortiger Wirkung ordentlich zu kündigen.
          Hinsichtlich der Form der Kündi-gung gilt Ziffer 7.1 S. 3. Eine
          Kündigung gemäß dieser Ziffer 7.2 stellt stets auch eine Kündigung
          gemäß Ziffer 7.3 dar.
        </p>

        <p>
          6.3. Abweichend von Ziffer 7.2 ist der Nutzer auch berechtigt,
          lediglich die Nutzung von zahlungspflichtigen Diensten gemäß Ziffer 6
          unter Fortführung der Nut-zung der C&D-App zu kündigen. Nimmt der
          Nutzer den zahlungspflichtigen Dienst im Wege eines Abonnements in
          Anspruch, so ist die Kündigung des Abonnements nur mit Wirkung zum
          Ende des laufenden wiederkehrenden Zeitabschnitts (zum Beispiel zum
          Ende des laufenden Kalendermonats) mög-lich.
        </p>

        <p>
          6.4. Mit der Wirksamkeit einer Kündigung gemäß Ziffern 7.1 oder 7.2
          ist der Nutzer zu Nutzung der C&D-App nicht mehr berechtigt.
        </p>

        <p>
          6.5. Das Unternehmen ist berechtigt, die C&D-App zu sperren bzw. durch
          den ent-sprechende App-Plattform sperren zu lassen, wenn (i) der
          Vertrag über die Nutzung der C&D-App aus wichtigem Grund vom
          Unternehmen gekündigt wer-den kann (siehe dazu Ziffer 7.1.), (ii) eine
          nicht autorisierte, rechtswidrige, ver-tragswidrige, strafbare
          und/oder missbräuchliche Nutzung der C&D-App statt-findet oder
          unmittelbar droht (z.B. wenn der Nutzer einem unberechtigtem Drit-ten
          sein mobiles Endgerät zur Nutzung der C&D-App überlässt), und/oder
          (iii) der Nutzer die Installation eines sicherheitsrelevanten
          Updates/Upgrades der C&D-App auf seinem mobilen Endgerät nicht
          vornimmt, obwohl er auf dessen Existenz in geeigneter Form hingewiesen
          wurde.
        </p>

        <p>7. Verfügbarkeit der C&D-App</p>

        <p>
          Das Unternehmen ist bestrebt im Rahmen des technisch Machbaren und
          wirtschaft-lich Zumutbaren eine uneingeschränkte Verfügbarkeit der
          C&D-App anzubieten. Das Unternehmen übernimmt hierfür allerdings keine
          Gewährleistung. Insbesondere War-tungsarbeiten, Sicherheits- und
          Kapazitätsgründe, technische Gegebenheiten sowie Ereignisse außerhalb
          des Herrschaftsbereichs des Unternehmens können zu einer
          vorübergehenden Nichtverfügbarkeit der C&D-App führen. Ansprüche des
          Nutzers gegen das Unternehmen sind für den Fall der Vereinbarung
          zahlungspflichtiger Dienste ausgeschlossen, soweit der addierte
          Zeitraum der Nichtverfügbarkeit weniger als 1 % des vereinbarten
          wiederkehrenden Zeitabschnitts (zum Beispiel eines Kalen-dermonats)
          beträgt.
        </p>

        <p>8. Datenschutz</p>

        <p>
          Das Unternehmen verarbeitet personenbezogene Daten im Rahmen des
          Erforderli-chen gemäß seiner Datenschutzerklärung, einsehbar
          unter&nbsp;
          <a href="https://tiramizoo.com/privacy">tiramizoo.com/privacy</a>.
        </p>

        <p>9. Haftung, Haftungsgrenzen</p>

        <p>
          9.1. Das Unternehmen haftet bei Vorsatz oder grober Fahrlässigkeit für
          alle von ihm sowie von seinen gesetzlichen Vertretern oder
          Erfüllungsgehilfen verursachten Schäden unbeschränkt.
        </p>

        <p>
          9.2. Bei leichter Fahrlässigkeit haftet das Unternehmen im Fall der
          Verletzung des Lebens, des Körpers oder der Gesundheit unbeschränkt.
        </p>

        <p>
          9.3. Im Übrigen haftet das Unternehmen nur, soweit es eine wesentliche
          Vertrags-pflicht (Kardinalpflicht) verletzt hat. In diesen Fällen ist
          die Haftung auf den Er-satz des vorhersehbaren, typischerweise
          eintretenden Schadens beschränkt. Die verschuldensunabhängige Haftung
          des Unternehmens auf Schadenser-satz (§ 536a BGB) für bei
          Vertragsschluss vorhandene Mängel wird ausge-schlossen; Ziffern 10.1
          und 10.2 bleiben unberührt.
        </p>

        <p>9.4. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>

        <p>10. Schlussbestimmungen</p>

        <p>
          10.1. Auf das Vertragsverhältnis findet deutsches Recht mit Ausnahme
          des Interna-tionalen Privatrechts Anwendung.
        </p>

        <p>
          10.2. Ergeben sich in der praktischen Anwendung dieser
          Nutzungsbedingungen Lü-cken, die die Vertragspartner nicht vorgesehen
          haben, oder wird die Unwirk-samkeit einer Regelung im Sinne von Ziffer
          11.3 rechtskräftig oder von beiden Parteien übereinstimmend
          festgestellt, so verpflichten sie sich, diese Lücke oder unwirksame
          Regelung in sachlicher, am wirtschaftlichen Zweck des Ver-trages
          orientierter angemessener Weise auszufüllen bzw. zu ersetzen.
        </p>

        <p>
          10.3. Im Geschäftsverkehr mit Unternehmern, juristischen Personen des
          öffentlichen Rechts oder bei öffentlich-rechtlichen Sondervermögen
          gilt als Erfüllungsort und ausschließlicher Gerichtsstand München.
          Gesetzlich zwingende Gerichts-stände bleiben unberührt.
        </p>

        <p>
          10.4. Die Europäische Kommission stellt eine Plattform bereit, die
          eine Online-Beilegung von Streitigkeiten zwischen Verbrauchern und
          Unternehmern ermög-licht (OS-Plattform). Die OS-Plattform ist unter
          dem folgenden Link zu errei-chen:{" "}
          <a href="https://ec.europa.eu/consumers/odr">
            ec.europa.eu/consumers/odr
          </a>
          . Die E-Mail-Adresse des Unterneh-mens lautet:&nbsp;
          <a href="mailto:support@tiramizoo.com">support@tiramizoo.com</a>. Das
          Unternehmen ist zur Teilnahme an Streitbeilegungsverfahren vor
          Verbraucherschlichtungsstellen weder verpflich-tet noch dazu bereit.
        </p>
      </article>
    </Layout>
  )
}

export default TermsPage
